import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import PageTop from "../components/PageTop";
import MenuArea from "../components/MenuArea";
import AandInfo from "../components/AandInfo";
import Map from "../components/Map";
import HooderButton from "../components/Hooder_button";
import SliderArea from "../components/SliderArea";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laki_hair_canpain: file(relativePath: { eq: "laki_hair_canpain.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laki_img1: file(relativePath: { eq: "laki_img1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laki_img2: file(relativePath: { eq: "laki_img2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const carouselElems = [
    {
      link: "/campaign",
      img: data.laki_hair_canpain.childImageSharp.fluid,
    },
    {
      link: "/#top",
      img: data.laki_img1.childImageSharp.fluid,
    },
    {
      link: "#top",
      img: data.laki_img2.childImageSharp.fluid,
    },
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#ffffff">
        <Header />
        <Box id="top">
          <PageTop />
        </Box>
        <Box id="campaign">
          <SliderArea carouselElems={carouselElems} />
        </Box>
        <Box id="menu">
          <MenuArea />
        </Box>
        <Box id="access">
          <AandInfo />
        </Box>
        <Map />
        <HooderButton />
        {/* <Box
          className="fv"
          width="90%"
          maxWidth="1200px"
          mx={"auto"}
          py="32px"
          position="relative"
        ></Box> */}
        <Box id="instagram">
          <Footer />
        </Box>
      </Box>
    </>
  );
}
