import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Slider from "react-slick";
import { Box, Grid, Button, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "./WrapTypo";
import Img from "gatsby-image";
import "../css/slickOverride.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import PrevArrowImg from "../../static/images/prevArrow.png";
// import NextArrowImg from "../../static/images/nextArrow.png";

const SliderArea = (props) => {
  const { carouselElems } = props;
  const data = useStaticQuery(graphql`
    query {
      laki_img1: file(relativePath: { eq: "laki_img1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laki_img2: file(relativePath: { eq: "laki_img2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laki_hair_canpain: file(relativePath: { eq: "laki_hair_canpain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <Box margin="auto" width="80%" textAlign="center">
        <Slider {...settings}>
          {carouselElems.map((pic) => {
            return (
              <Box>
                <Link to={pic.link}>
                  <Img fluid={pic.img} />
                </Link>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </>
  );
};

export default SliderArea;
