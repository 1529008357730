import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { Box, Button } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "./WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const Hooder_button = () => {
  const data = useStaticQuery(graphql`
    query {
      chevronUp: file(relativePath: { eq: "chevron-up.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Box maxWidth="1200px" margin="auto" bgcolor="#ffffff" mt={12}>
        <Box margin="auto" width={["18%", "15%", "15%", "15%"]}>
          <Button onClick={returnTop} style={{ margin: "auto", width: "100%" }}>
            <Img
              fluid={data.chevronUp.childImageSharp.fluid}
              style={{ margin: "auto", width: "100%" }}
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Hooder_button;
