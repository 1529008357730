import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "./WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const AandInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      Lakilogo: file(relativePath: { eq: "Lakilogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box maxWidth="1200px" margin="auto" bgcolor="#ffffff">
        <Box margin="auto" textAlign="center" width="90%" my={6}>
          <WrapTypo AmaticSC fw="500" fs="80px" color="#4A2609">
            ACCESS&INFO
          </WrapTypo>
        </Box>
        <Box margin="auto" width="80%" mb={8}>
          <Box width={["100%", "80%", "70%", "60%"]} margin="auto">
            <WrapTypo color="#000000">Laki hair（ラキヘアー）</WrapTypo>
            <WrapTypo color="#000000">
              店舗住所　〒990-0034 山形県山形市東原町３丁目１−２
            </WrapTypo>
            <WrapTypo color="#000000">
              営業時間　平日 10:00〜18:00 / 土・日・祝日 10:00〜18:00
            </WrapTypo>
            <WrapTypo color="#000000">定休日　　火曜日</WrapTypo>
          </Box>
        </Box>
        <Box margin="auto" textAlign="center" mb={4}>
          <WrapTypo color="#000000">
            下記のお電話にて、電話受付時間内に
            <br />
            ご予約くださいませ
          </WrapTypo>
          <Box mt={5}>
            <WrapTypo color="#000000" lh="200%">
              電話受付時間　
              <br />
              平日 土・日・祝日 9:30〜17:00
            </WrapTypo>
          </Box>
        </Box>
        <Box margin="auto" textAlign="center" mb={8}>
          {/* <a href="tel:023-609-9333">023-609-9333</a> */}
          <Link href="tel:023-609-9333">
            <WrapTypo fs="35px" color="#000000">
              023-609-9333
            </WrapTypo>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default AandInfo;
