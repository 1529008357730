import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { Box, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const Map = () => {
  const data = useStaticQuery(graphql`
    query {
      anj: file(relativePath: { eq: "anjusImgProfile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden xsDown>
        <Box maigin="auto" textAlign="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12534.186590257434!2d140.3420176!3d38.243626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4557f516b0d85127!2zTGFraSBoYWly77yI44Op44Kt44OY44Ki44O877yJ!5e0!3m2!1sja!2sjp!4v1628999546207!5m2!1sja!2sjp"
            width="600px"
            height="450px"
            frameborder="0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box maigin="auto" textAlign="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12534.186590257434!2d140.3420176!3d38.243626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4557f516b0d85127!2zTGFraSBoYWly77yI44Op44Kt44OY44Ki44O877yJ!5e0!3m2!1sja!2sjp!4v1628999546207!5m2!1sja!2sjp"
            width="315px"
            height="320px"
            frameborder="0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Box>
      </Hidden>
    </>
  );
};

export default Map;
