import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "./WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const MenuArea = () => {
  const data = useStaticQuery(graphql`
    query {
      Lakilogo: file(relativePath: { eq: "Lakilogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box maxWidth="1200px" margin="auto" bgcolor="#ffffff">
        <Box margin="auto" textAlign="center" width="90%" my={6}>
          <WrapTypo AmaticSC fw="500" fs="90px" color="#4A2609">
            MENU
          </WrapTypo>
        </Box>
        <Box margin="auto" width="80%" mb={5}>
          <Grid container>
            <Grid item xs={12} sm={6} style={{ height: "150px" }}>
              <Box width={["90%", "80%", "60%", "60%"]} margin="auto" pb={2}>
                <Box pb={1}>
                  <WrapTypo fs="25px" color="#000000">
                    CUT
                  </WrapTypo>
                </Box>
                <WrapTypo color="#000000" lh="200%">
                  一般 ¥2,000
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  中学生以下 ¥1,000
                </WrapTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: "150px" }}>
              <Box width={["90%", "80%", "60%", "60%"]} margin="auto" pb={2}>
                <Box pb={1}>
                  <WrapTypo fs="20px" color="#000000">
                    SHAMPOO BLOW
                  </WrapTypo>
                </Box>
                <WrapTypo color="#000000" lh="200%">
                  シャンプー&ブロー　¥1,500
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  型つけ　¥2,000
                </WrapTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: "120px" }}>
              <Box width={["90%", "80%", "60%", "60%"]} margin="auto">
                <Box pb={1}>
                  <WrapTypo fs="25px" color="#000000">
                    COLOR
                  </WrapTypo>
                </Box>
                <WrapTypo color="#000000" lh="200%">
                  シャンプー&ブロー込み　¥6,000
                </WrapTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: "150px" }}>
              <Box width={["90%", "80%", "60%", "60%"]} margin="auto" mb={2}>
                <Box pb={1}>
                  <WrapTypo fs="25px" color="#000000">
                    PERM
                  </WrapTypo>
                </Box>
                <WrapTypo color="#000000" lh="200%">
                  パーマ　¥6,000〜¥7,000
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  縮毛矯正　¥10,000
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  キュアチェンジ　¥6,000
                </WrapTypo>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ height: "150px" }}>
              <Box width={["90%", "80%", "60%", "60%"]} margin="auto" pb={2}>
                <Box pb={1}>
                  <WrapTypo fs="25px" color="#000000">
                    OTHER
                  </WrapTypo>
                </Box>
                <WrapTypo color="#000000" lh="200%">
                  セットアップ　¥4,000
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  メイク　¥1,500
                </WrapTypo>
                <WrapTypo color="#000000" lh="200%">
                  着付け　¥4,000
                </WrapTypo>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default MenuArea;
